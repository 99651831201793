// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cadastro-petshop-dados-acesso-js": () => import("./../../../src/pages/cadastro-petshop/dados-acesso.js" /* webpackChunkName: "component---src-pages-cadastro-petshop-dados-acesso-js" */),
  "component---src-pages-cadastro-petshop-erro-alcance-js": () => import("./../../../src/pages/cadastro-petshop/erro-alcance.js" /* webpackChunkName: "component---src-pages-cadastro-petshop-erro-alcance-js" */),
  "component---src-pages-cadastro-petshop-sucesso-cadastro-js": () => import("./../../../src/pages/cadastro-petshop/sucesso-cadastro.js" /* webpackChunkName: "component---src-pages-cadastro-petshop-sucesso-cadastro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-termos-de-uso-index-jsx": () => import("./../../../src/pages/termos-de-uso/index.jsx" /* webpackChunkName: "component---src-pages-termos-de-uso-index-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

